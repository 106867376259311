@import "~@/common.scss";
.cmp-m-nav {
  display: flex;
  flex-direction: column;
  width: px2rem(78px);
  height: px2rem(432px);
  padding: px2rem(38px) 0 px2rem(41px) px2rem(8px);

  &::after {
    content: "";
    position: absolute;
    width: px2rem(2px);
    height: 100%;
    border-radius: px2rem(1px);
    top: 0;
    right: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 205, 74, 1),
      rgba(255, 205, 74, 0)
    );
    opacity: 0.2;
    z-index: -1;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: px2rem(70px);
    & > img:first-child {
      width: px2rem(30px);
      height: px2rem(21px);
      object-fit: contain;
      margin-left: px2rem(15px);
    }
    & > img:last-child {
      width: px2rem(30px);
      height: px2rem(30px);
      position: absolute;
      right: px2rem(-15px);
    }
    &:nth-child(5) {
      & > img:first-child {
        width: px2rem(54px);
        height: px2rem(14px);
        margin-left: unset;
      }
    }
  }
}
