@import "~@/common.scss";
.pg-channel-home .cmp-personal-honor {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: flex-start;
  &__text {
    width: px2rem(245px * 3);
    height: px2rem(28px * 3);
    margin-top: px2rem(20px * 3);
    @include setMedia('mobile') {
      width: px2rem(245px);
      height: px2rem(28px);
      margin-top: px2rem(20px);
    }
  }
  &__banner {
    width: px2rem(150px * 3);
    height: unset;
    margin-bottom: px2rem(13px);
    object-fit: contain;
    @include setMedia('phone') {
      width: px2rem(150px);
    }
  }
  &__banner-box {
    display: flex;
    margin-bottom: px2vw(50px);
    &-item {
      display: flex;
      flex-direction: column;
      margin-right: px2vw(14px);

      &:last-child {
        margin-right: unset;
      }
    }
    &-person {
      width: px2vw(359px);
      height: px2vw(294px);
    }
    &-card {
      position: relative;
      width: px2vw(359px);
      height: px2vw(308px);
      // transition: 0.5s;
      transform-style: preserve-3d; /*指定所有子元素在3D空间内呈现*/
      z-index: 999;
      &:hover {
        // transform: rotateY(180deg);
        .cmp-personal-honor__banner-box-card {
          &-back {
            transform: rotateY(0deg); /*旋转180度,转向背面*/
          }
          &-mask {
            transform: rotateY(180deg); /*旋转180度,转向背面*/
          }
        }
      }
      &-mask,
      &-back {
        transition: 0.5s;
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden; /*定义当元素背面向屏幕时不可见*/
      }
      &-mask {
        z-index: 1;
        transform: rotateY(0deg);
      }
      &-back {
        z-index: 2;
        transform: rotateY(180deg);
      }
    }
  }
}
