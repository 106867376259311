@import '../common.scss';

.cmp-loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color: #fff, $alpha: .2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  svg{
    width: px2vw(100px);
    height:  px2vw(100px);
    @include setMedia('mobile') {
     width: px2rem(50px);
     height: px2rem(50px);
    }
  }
}