@import "~@/common.scss";
.pg-channel-home .cmp-home {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: px2vw(-100px);
  overflow: hidden;
  display: unset;
  margin-left: unset;
  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    margin-bottom: unset;
    &__person {
      @include setMedia('mobile') {
        width: px2rem(240px);
        height: px2rem(345px);
      }
    }
    &__text {
      width: px2rem(650px);
      height: px2rem(292px);
      margin: unset;
      margin-top: px2rem(120px);
      margin-bottom: px2rem(60px);
      @include setMedia('mobile') {
        width: px2rem(162px);
        height: px2rem(73px);
        margin: unset;
        margin-top: px2rem(40px);
        margin-bottom: px2rem(20px);
      }
    }
  }
}
