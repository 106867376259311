@import "~@/common.scss";
.cmp-game {
  height: 100%;
  padding-top: px2vw(60px);
  display: flex;
  padding-left: px2vw(28px);
  overflow: hidden;

  @include setMedia("mobile") {
    padding: unset;
    flex-direction: column;
    overflow: scroll;
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    margin-right: px2vw(42px);
    @include setMedia("mobile") {
      margin: unset;
      align-items: unset;
    }
    .top {
      width: px2vw(709px);
      height: px2vw(81px);
      margin-bottom: px2vw(63px);
      @include setMedia("mobile") {
        width: px2rem(262px);
        height: px2rem(24px);
        margin-top: px2rem(20px);
        margin-bottom: px2rem(14px);
      }
    }
    .middle {
      display: flex;
      @include setMedia("mobile") {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      & > .item {
        width: px2vw(288px);
        height: px2vw(404px);
        position: relative;
        padding-top: px2vw(46px);
        @include setMedia("mobile") {
          width: px2rem(167px);
          height: px2rem(187px);
          margin-bottom: px2rem(15px);
        }
        &:nth-child(2n) {
          margin: 0 px2vw(17px);
          @include setMedia("mobile") {
            margin: unset;
            margin-bottom: px2rem(15px);
          }
        }
        img {
          position: absolute;
          inset: 0;
          z-index: -1;
        }
        img,
        .pc-img {
          width: 100%;
          height: 100%;
          transition: all 0.3s linear;
        }
        &:nth-child(1) {
          &:hover {
            .pc-img {
              background-image: url("~@/images/game-ludo-back.png");
            }
          }
          .pc-img {
            background: url("~@/images/game-ludo-mask.png") no-repeat
              center/cover;
          }
        }
        &:nth-child(2) {
          &:hover {
            .pc-img {
              background-image: url("~@/images/game-domino-back.png");
            }
          }
          .pc-img {
            background: url("~@/images/game-domino-mask.png") no-repeat
              center/cover;
          }
        }
        &:nth-child(3) {
          &:hover {
            .pc-img {
              background-image: url("~@/images/game-knife-back.png");
            }
          }
          .pc-img {
            background: url("~@/images/game-knife-mask.png") no-repeat
              center/cover;
          }
        }
      }
    }
  }
   &__right {
    width: px2vw(579px);
    height: px2vw(900px);
    background: url("~@/images/room-phone.png") no-repeat;
    background-size: cover;
    overflow: hidden;
    margin: auto 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
