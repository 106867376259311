@import "~@/common.scss";

.pg-home {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: url("~@/images/bg.png") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;

  @include setMedia("mobile") {
    background-position: 60%;
  }
  @include setMedia("pc") {
    background-position: 40%;
  }
  @include setMedia("pad") {
    // height: auto;
    min-height: px2rem(800px);
    overflow: scroll;
  }

  header {
    width: 100%;
    @include setMedia("mobile") {
      .cmp-safe-area.top {
        background-color: rgba(10, 10, 12, 0.4);
      }
    }
    & > .content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: px2vw(20px) px2vw(40px);
      background-color: rgba(10, 10, 12, 0.2);

      @include setMedia("mobile") {
        background-color: rgba(10, 10, 12, 0.4);
        padding: px2rem(15px);
      }
      & > .left {
        height: 100%;
        display: flex;
        align-items: center;
        img:first-child {
          width: px2vw(80px);
          height: px2vw(80px);
          object-fit: cover;
          margin-right: px2vw(28px);

          @include setMedia("mobile") {
            margin-right: px2rem(14px);
            width: px2rem(40px);
            height: px2rem(40px);
          }
        }
        img:last-child {
          width: px2vw(397px);
          height: px2vw(69px);
          object-fit: cover;
          @include setMedia("mobile") {
            width: px2rem(102px);
            height: px2rem(40px);
          }
        }
      }
      & > .right {
        height: 100%;
        display: flex;
        align-items: center;
        & > img {
          cursor: pointer;
        }
        .user-info {
          display: flex;
          align-items: center;
          margin-right: px2vw(40px);
          @include setMedia("mobile") {
            margin-right: px2rem(15px);
          }
          img {
            width: px2vw(60px);
            height: px2vw(60px);
            border-radius: 50%;
            margin-right: px2vw(15px);
            @include setMedia("mobile") {
              width: px2rem(30px);
              height: px2rem(30px);
              margin: unset;
            }
          }
          span {
            font-size: px2vw(28px);
            color: #ffffff;
            @include setMedia("mobile") {
              display: none;
            }
          }
        }
        .more {
          width: px2vw(60px);
          height: px2vw(60px);
          position: relative;
          @include setMedia("mobile") {
            display: none;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
          }
          &-list {
            z-index: 999;
            position: absolute;
            width: px2vw(238px);
            height: 0;
            opacity: 0;
            overflow: hidden;
            background: url("~@/images/more-list-bg.png") no-repeat;
            background-size: cover;
            right: 0;
            top: px2vw(89px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: all 0.3s linear;
            border-radius: px2rem(8px);
            &-item {
              font-size: px2vw(28px);
              font-weight: 500;
              color: #3a311c;
              border-bottom: px2vw(2px) solid rgba(58, 49, 28, 1);
              padding-bottom: px2vw(30px);
              margin-bottom: px2vw(30px);
              cursor: pointer;

              &:last-child {
                margin-bottom: unset;
                padding-bottom: unset;
                border-bottom: unset;
              }
            }
          }
          .open-more {
            height: px2vw(319px);
            opacity: 1;
          }
          .open-more-nouser {
            height: px2vw(200px);
          }
        }
      }
      .login,
      .top-up {
        width: px2vw(160px);
        height: px2vw(60px);
        object-fit: cover;
        margin-right: px2vw(40px);
        @include setMedia("mobile") {
          width: px2rem(80px);
          height: px2rem(30px);
          margin-right: unset;
        }
      }
      .login {
        @include setMedia("mobile") {
          display: none;
        }
      }
    }
  }
  & > .swiper {
    flex-grow: 1;
    padding-left: px2vw(338px);
    margin: unset;
    @include setMedia("mobile") {
      // padding-left: unset;
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      // @include setMedia("mobile") {
      //   padding-left: px2rem(100px);
      // }
    }
  }

  nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99999;
  }

  &-company {
    position: absolute;
    top: px2vw(1020px);
    width: 100%;
    text-align: center;

    p {
      margin-top: 0;
      font-size: px2vw(18px);
      color: #fff;
      margin-bottom: px2vw(10px);
    }
  }
}
