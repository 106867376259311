@import "~@/common.scss";
.cmp-m-log-out {
  position: fixed;
  inset: 0;
  background-color: rgba(27, 25, 29, 0.7);
  z-index: 99999;
  backdrop-filter: blur(px2rem(6px));
  display: flex;
  align-items: flex-end;

  &__dialog {
    z-index: 10000000;
    width: 100%;
    height: px2rem(275px);
    background: url("~@/images/m-log-bg.png") no-repeat center/contain;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__close {
    position: absolute;
    width: px2rem(24px);
    height: px2rem(24px);
    right: px2rem(14px);
    top: px2rem(14px);
    user-select: none;
  }
  &__logo {
    width: px2rem(50px);
    height: px2rem(50px);
    margin-bottom: px2rem(51px);
  }
  &__text {
    font-size: px2rem(16px);
    font-weight: bold;
    color: #dfb474;
    margin-bottom: px2rem(51px);
  }
  &__btn {
    width: px2rem(320px);
    height: px2rem(30px);
  }
}
