@import "~@/common.scss";
.cmp-home {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: px2vw(-100px);
  overflow: hidden;

  @include setMedia("mobile") {
    display: unset;
    margin-left: unset;
  }
  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: px2vw(120px);
    @include setMedia("mobile") {
      flex-direction: column-reverse;
      margin-bottom: unset;
    }
    &__person {
      width: px2vw(774px);
      height: px2vw(805px);
      @include setMedia("mobile") {
        width: px2rem(240px);
        height: px2rem(345px);
      }
    }
    &__text {
      width: px2vw(785px);
      height: px2vw(342px);
      margin: 0 px2vw(14px);
      @include setMedia("mobile") {
        width: px2rem(162px);
        height: px2rem(73px);
        margin: unset;
        margin-top: px2rem(40px);
        margin-bottom: px2rem(20px);
      }
    }
  }
}
