@function px2rem($px) {
  @return calc($px / 37.5px) * 1rem;
}

@function px2vw($px) {
  //1920不行，元素会超出裁剪
  @return calc($px / 2180px) * 100vw;
}

// 创建数据集
$arr: (
  "phone": (
    320px,
    480px,
  ),
  "pad": (
    481px,
    768px,
  ),
  "pc": (
    769px,
    1024px,
  ),
  "desktop": (
    1025px,
    1200px,
  ),
  "tv": 1201px,
  // 涵盖phone和pad
  "mobile":
    (
      320px,
      1024px//ipad pro
    ),
);
// 定义一个混入方法@mini setMedia(){}
@mixin setMedia($key) {
  // 根据key来获取数组项
  $bp: map-get($arr, $key); // map-get 方法 返回 key的索引值
  @if type-of($bp) == "list" {
    // type-of 类型判断(x,y)结构返回 list
    $min: nth($bp, 1); // nth(数组,索引) 下标从1开始 返回索引的值
    $max: nth($bp, 2);
    @media (min-width: $min) and (max-width: $max) {
      @content; // @content 可以当做一个插槽
    }
  } @else {
    @media (min-width: $bp) {
      @content;
    }
  }
}

@import 'swiper/css';