@import "~@/common.scss";
.cmp-log-in {
  direction: rtl;
  position: fixed;
  inset: 0;
  background-color: rgba(27, 25, 29, 0.7);
  z-index: 99999;
  backdrop-filter: blur(px2vw(6px));
  display: flex;
  justify-content: center;
  align-items: center;
  @include setMedia("mobile") {
    backdrop-filter: blur(px2rem(6px));
    align-items: flex-end;
  }
  &__dialog {
    z-index: 10000000;
    width: px2vw(860px);
    height: px2vw(500px);
    background: url("~@/images/log-bg.png") no-repeat center/contain;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include setMedia("mobile") {
      width: 100%;
      height: px2rem(275px);
      background-image: url("~@/images/m-log-bg.png");
    }
  }
  &__close {
    width: px2vw(60px);
    height: px2vw(60px);
    position: absolute;
    right: px2vw(-18px);
    top: px2vw(-14px);
    cursor: pointer;
    user-select: none;
    @include setMedia("mobile") {
      width: px2rem(24px);
      height: px2rem(24px);
      right: px2rem(14px);
      top: px2rem(14px);
    }
  }
  &__logo {
    width: px2vw(100px);
    height: px2vw(100px);
    margin-bottom: px2vw(40px);
    @include setMedia("mobile") {
      width: px2rem(50px);
      height: px2rem(50px);
      margin-bottom: px2rem(20px);
    }
  }
  &__naadi,
  &__password {
    all: unset;
    border-radius: px2vw(2px);
    background-color: rgba(0, 0, 0, 0.2);
    border: px2vw(1px) solid rgba(203, 187, 144, 1);
    text-indent: px2vw(26px);
    color: rgba($color: #fff, $alpha: 0.4);
    @include setMedia("mobile") {
      font-size: px2rem(14px);
      border-radius: px2rem(2px);
      border: px2rem(1px) solid rgba(203, 187, 144, 1);
      text-indent: px2rem(10px);
    }
  }
  &__naadi,
  &__password,
  &__btn {
    width: px2vw(700px);
    height: px2vw(60px);
    margin-bottom: px2vw(40px);
    @include setMedia("mobile") {
      width: px2rem(320px);
      height: px2rem(30px);
      margin-bottom: px2rem(20px);
    }
  }

  &__btn {
    background: none;
    border: none;
    background: url("~@/images/log-btn.png") no-repeat center/cover;
    object-fit: cover;
    cursor: pointer;
  }
  .gray {
    background-image: url("~@/images/log-btn-gray.png");
  }
}
