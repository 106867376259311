@import "~@/common.scss";
.pg-channel-home .cmp-channel-game {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  &__left {
    display: flex;
    flex-direction: column;
    margin: unset;
    .top {
      width: px2rem(262px * 3);
      height: px2rem(24px * 3);
      margin-top: px2rem(20px * 3);
      margin-bottom: px2rem(14px * 3);
      @include setMedia('mobile') {
        width: px2rem(262px);
        height: px2rem(24px);
        margin-top: px2rem(20px);
        margin-bottom: px2rem(14px);
      }
    }
    .middle {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      & > .item {
        width: px2rem(130px * 3);
        object-fit: contain;
        position: relative;

        @include setMedia('mobile') {
          width: px2rem(130px);
          margin-bottom: px2rem(15px);
        }

        &:not(:last-child) {
          margin: unset;
          margin-bottom: px2vw(15px * 3);
        }
        // img {
        //   position: absolute;
        //   inset: 0;
        //   z-index: -1;
        // }
        // img {
        //   width: 100%;
        //   height: 100%;
        //   transition: all 0.3s linear;
        // }
      }
    }
  }
  &__right {
    width: px2vw(579px);
    height: px2vw(900px);
    padding: px2vw(25px) px2vw(26px);
    padding-bottom: 0;
    overflow: hidden;
    margin: auto 0;
    margin: unset;
    background: unset;
    width: px2rem(261px);
    height: px2rem(345px);
    img {
      width: 100%;
      height: 100%;
    }
  }
}
