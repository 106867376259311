@import "~@/common.scss";

.pg-channel-home {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: url("~@Channel/images/bg.png") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  background-position: 60%;

  header {
    width: 100%;
    .cmp-safe-area.top {
      background-color: rgba(10, 10, 12, 0.4);
    }
    & > .content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(10, 10, 12, 0.4);
      padding: px2rem(15px);
      & > .left {
        height: 100%;
        display: flex;
        align-items: center;
        img:first-child {
          width: px2vw(80px);
          height: px2vw(80px);
          object-fit: cover;
          margin-right: px2vw(28px);

          @include setMedia("mobile") {
            margin-right: px2rem(14px);
            width: px2rem(40px);
            height: px2rem(40px);
          }
        }
        img:last-child {
          width: px2vw(397px);
          height: px2vw(69px);
          object-fit: cover;
          @include setMedia("mobile") {
            width: px2rem(102px);
            height: px2rem(40px);
          }
        }
      }
      & > .right {
        height: 100%;
        display: flex;
        align-items: center;
        & > img {
          cursor: pointer;
        }
        .login,
        .top-up {
          width: px2vw(160px);
          height: px2vw(60px);
          margin-right: px2vw(40px);
          background-image: url(~@Channel/images/download.png); /* 替换为你的图片路径 */
          background-size: cover; /* 使图片覆盖整个按钮 */
          background-position: center; /* 使图片居中 */
          border: none; /* 去掉按钮边框 */
          cursor: pointer; /* 鼠标悬停时显示为手型 */
          @include setMedia("mobile") {
            width: px2rem(80px);
            height: px2rem(30px);
            margin-right: unset;
          }
        }
        .user-info {
          display: flex;
          align-items: center;
          margin-right: px2vw(40px);
          @include setMedia("mobile") {
            margin-right: px2rem(15px);
          }
          img {
            width: px2vw(60px);
            height: px2vw(60px);
            border-radius: 50%;
            margin-right: px2vw(15px);
            @include setMedia("mobile") {
              width: px2rem(30px);
              height: px2rem(30px);
              margin: unset;
            }
          }
          span {
            font-size: px2vw(28px);
            color: #ffffff;
            @include setMedia("mobile") {
              display: none;
            }
          }
        }
      }
    }
  }
  & > .swiper {
    flex-grow: 1;
    padding-left: px2vw(338px);
    width: 100%;
    box-sizing: border-box;
    @include setMedia("mobile") {
      padding-left: px2vw(538px);
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      // @include setMedia("mobile") {
      //   padding-left: px2rem(100px);
      // }
    }
  }

  nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99999;
  }

  &-company {
    position: absolute;
    top: px2vw(1020px);
    width: 100%;
    text-align: center;

    p {
      margin-top: 0;
      font-size: px2vw(18px);
      color: #fff;
      margin-bottom: px2vw(10px);
    }
  }
}
