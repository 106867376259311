@import "~@/common.scss";
.cmp-download {
  width: 100%;
  z-index: 99;
  position: fixed;
  bottom: px2vw(60px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  @include setMedia("mobile") {
    display: flex;
    justify-content: center;
    bottom: px2rem(35px);
  }
  &__item {
    width: px2vw(300px);
    height: px2vw(112px);
    user-select: none;
    cursor: pointer;
    flex-shrink: 0;
    @include setMedia("mobile") {
      width: px2rem(150px);
      height: px2rem(56px);
    }
    &:not(:last-child) {
      margin-right: px2vw(44px);
      @include setMedia("mobile") {
        margin-right: px2rem(20px);
      }
    }
    &-img {
      width: 100%;
    }
  }
}
