@import "~@/common.scss";

.cmp-custom-made {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @include setMedia("mobile") {
    overflow: scroll;
  }
  &__text {
    width: px2vw(960px);
    height: px2vw(192px);
    margin-bottom: px2vw(82px);
    @include setMedia("mobile") {
      width: px2rem(235px);
      height: px2rem(28px);
      margin-top: px2rem(20px);
      margin-bottom: px2rem(15px);
    }
  }
  & > .swiper2 {
    flex-shrink: 0;
    width: px2vw(1260px);
    height: px2vw(500px);
    overflow: hidden;
    position: relative;
    background: url("(~@/images/swiper-bg.png") no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 px2vw(100px);
    @include setMedia("mobile") {
      width: px2rem(221px);
      height: px2rem(551px);
      background: url("(~@/images/m-swiper-bg.png") no-repeat;
      background-size: contain;
      padding: unset;
      margin-bottom: px2rem(28px);
    }
    .swiper-container {
      position: relative;
      width: 100%;
      height: px2vw(346px);
      overflow: hidden;
      @include setMedia("mobile") {
        height: px2rem(460px);
        touch-action: unset;
      }
    }

    .swiper-slide {
      transition: 300ms;
      opacity: 0;
      transform: scale(0.8);
      @include setMedia("mobile") {
        padding-left: unset;
      }
      .img-content {
        position: relative;
        user-select: none;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          inset: 0;
          background-color: rgba($color: #000, $alpha: 0.6);
        }
      }
      img {
        width: px2vw(346px);
        height: px2vw(346px);
        object-fit: contain;
        @include setMedia("mobile") {
          width: px2rem(153px);
          height: px2rem(153px);
        }
      }
    }

    .swiper-slide-active {
      z-index: 999;
      transform: scale(1);
      opacity: 1;
      .img-content {
        &::after {
          all: unset;
        }
      }
      // 下一个
      + .swiper-slide {
        z-index: 88;
        opacity: 1;
      }
    }
    // 上一个
    .swiper-slide:has(+ .swiper-slide-active) {
      z-index: 88;
      opacity: 0.8;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: px2vw(72px);
      height: px2vw(72px);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 99;
      cursor: pointer;
      @include setMedia("mobile") {
        all: unset;
        position: absolute;
        width: px2rem(36px);
        height: px2rem(36px);
        transform: translateX(-50%) rotate(90deg);
      }
    }
    .swiper-button-prev {
      left: px2vw(18px);
      background: url("(~@/images/prev-btn.png") no-repeat;
      background-size: cover;
      @include setMedia("mobile") {
        left: 50%;
        top: px2rem(6px);
      }
    }

    .swiper-button-next {
      right: px2vw(18px);
      background: url("(~@/images/next-btn.png") no-repeat;
      background-size: cover;
      @include setMedia("mobile") {
        left: 50%;
        bottom: px2rem(6px);
      }
    }

    .swiper-slide:has(+ .swiper-slide-prev) {
      opacity: 1;
      transform: scale(0.6);
    }
    .swiper-slide-next + .swiper-slide {
      opacity: 1;
      transform: scale(0.6);
    }
  }
}
