@import "~@/common.scss";
.cmp-room {
  height: 100%;
  padding-top: px2vw(32px);
  padding-left: px2vw(101px);
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
  @include setMedia("mobile") {
    padding: unset;
    flex-direction: column;
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    margin-right: px2vw(128px);
    @include setMedia("mobile") {
      margin: unset;
      margin-bottom: px2rem(24px);
    }
    .top {
      width: px2vw(458px);
      height: px2vw(149px);
      margin-bottom: px2vw(23px);
      @include setMedia("mobile") {
        width: px2rem(200px);
        height: px2rem(60px);
        margin-top: px2rem(58px);
      }
    }
    .middle {
      width: px2vw(548px);
      height: px2vw(203px);
      position: relative;
      margin-bottom: px2vw(25px);
      &::after {
        content: "";
        position: absolute;
        inset: 0;
        z-index: 1;
        background: url("~@/images/room-gift-banner-mask.png") no-repeat
          center/cover;
          transition: all 0.3s linear;
      }
      @include setMedia("mobile") {
        display: none;
      }
      &:hover {
        &::after {
          opacity: 0;
        }
      }
      img {
        z-index: 2;
      }
      img,
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .bottom {
      display: flex;
      @include setMedia("mobile") {
        display: none;
      }
      .car,
      .gift {
        width: px2vw(253px);
        height: px2vw(500px);
        position: relative;
        &::after {
          content: "";
          position: absolute;
          inset: 0;
          z-index: 1;
          background: url("~@/images/room-gift-mask.png") no-repeat center/cover;
          transition: all 0.3s linear;
        }
        &:hover {
          &::after {
            opacity: 0;
          }
        }
        img {
          z-index: 2;
        }
        img,
        video {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .car {
        margin-right: px2vw(42px);
        &::after {
          background-image: url("~@/images/room-car-mask.png");
        }
      }
    }
  }
  &__right {
    width: px2vw(428px);
    height: px2vw(880px);
    background: url("~@/images/room-phone.png") no-repeat;
    background-size: cover;
    overflow: hidden;
    margin: auto 0;
    img {
      width: 100%;
    }
    @include setMedia('mobile') {
      margin: unset;
      width: unset;
      height: unset;
      background: unset;
      text-align: center;
      background-size: cover;
      overflow: hidden;
      img {
        width: px2rem(160px);
      }
    }
  }
}
