@import "~@/common.scss";

.cmp-nav {
  // 旋转36度每次
  // 数字1:46，数字2:10，数字3:-26，数字4：-62，数字5：-98
  --nav-rotate: 46deg; // nav初始旋转角度，即展示到数字1的位置
  --num-rotate: 36deg; //数字初始旋转角度，即180deg/5，半圆角度/元素数量

  &-pc {
    width: px2vw(294px);
    height: px2vw(588px);
    background: url("~@/images/circle-line.png") no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &__title {
      width: px2vw(100px);
      height: px2vw(60px);
      position: relative;
      z-index: 999;
      object-fit: contain;
    }
    &__title-long {
      width: px2vw(180px);
      height: px2vw(100px);
      object-fit: contain;
    }
    &__fog {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: px2vw(395px);
      height: px2vw(222px);
      background: url("~@/images/fog.png") no-repeat;
      background-size: contain;
    }
    &__nav {
      position: absolute;
      width: calc(px2vw(588px) + px2vw(44px));
      height: calc(px2vw(588px) + px2vw(44px));
      left: px2vw(-294px);
      border-radius: 50%;
      transform: rotate(var(--nav-rotate));
      transition: all 0.5s;
      z-index: 99;
      user-select: none;

      & > .img-box {
        width: px2vw(88px);
        height: px2vw(88px);
        /* 旋转基点，在整个父元素中间 ,圆半径的一半*/
        transform-origin: 0 ((px2vw(588px) + px2vw(44px)) / 2);
        /* 同样定位叠起来 */
        position: absolute;
        left: 50%;
        cursor: pointer;
        & > img {
          width: 100%;
          height: 100%;
        }
        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            transform: rotate(calc(var(--num-rotate) * $i));
            & > img {
              // 图片取反转为正
              transform: rotate(
                calc((var(--num-rotate) * -1) * $i - var(--nav-rotate))
              );
            }
          }
        }
      }
    }
  }
}
